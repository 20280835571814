define("itarp-landing-frontend/controllers/signin", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "itarp-landing-frontend/utils/sso", "itarp-landing-frontend/config/environment", "itarp-landing-frontend/utils/validation", "@ember/service"], function (_exports, _controller, _tracking, _object, _sso, _environment, _validation, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SigninController = (_class = class SigninController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "links", _descriptor, this);
      _initializerDefineProperty(this, "auth", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "user", _descriptor4, this);
      _initializerDefineProperty(this, "inputTypes", _descriptor5, this);
      _initializerDefineProperty(this, "errors", _descriptor6, this);
      _initializerDefineProperty(this, "fieldError", _descriptor7, this);
      _initializerDefineProperty(this, "isSignInInProgress", _descriptor8, this);
      _defineProperty(this, "queryParams", ['opportunityId', 'talentCloudId', 'email', 'referrer']);
      _defineProperty(this, "tokenBasedUserTypes", {
        guest_user: 'guest',
        expert: 'expert'
      });
      _defineProperty(this, "landingPages", {
        guest_user: 'landing-page-guest',
        tod_client: 'landing-page',
        experfy_staff_user: `${_environment.default.FRONTEND_CORE_URL}/task-dashboard`,
        expert: 'landing-page-expert'
      });
      _defineProperty(this, "signInVerificationErrors", {
        password_expired: 'Your password is expired',
        verification_pending: 'You need to verify the email first',
        password_expiring: 'Your password is about to expire. Change it now',
        blocked: 'Your account is blocked. You may contact the administrator'
      });
    }
    updateUser(property, event) {
      this.user[property] = event.target.value;
    }
    validateFields() {
      const {
        validationResult,
        fieldError
      } = (0, _validation.validateRequiredFields)(this.user, {
        isSignIn: true
      });
      if (this.user.email && !(0, _validation.validateEmail)(this.user.email)) {
        fieldError['email'] = true;
        validationResult['email'] = 'Email is not valid';
      }
      this.fieldError = fieldError;
      Object.keys(this.user).forEach(key => {
        if (validationResult[`${key}`]) {
          this.errors.pushObject(validationResult[`${key}`]);
        }
      });
      return this.errors.length === 0;
    }
    get userEmail() {
      if (this.email) {
        return this.email;
      } else if (this.user.email) {
        return this.user.email;
      }
      return '';
    }
    forgotPasswordHandler() {
      const url = this.getB2CRedirectURL('B2C_1A_PASSWORD_RESET', {
        redirectUrl: `${_environment.default.B2C_REDIRECT_URI}/signin`
      });
      return window.location.assign(url);
    }
    handleSignInKeydown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.signIn();
      }
    }
    signIn() {
      this.errors = [];
      if (!this.user.email && this.userEmail) this.user.email = this.userEmail;
      if (this.validateFields()) {
        this.isSignInInProgress = true;
        try {
          fetch('/api/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: this.user.email,
              password: this.user.password
            })
          }).then(async response => {
            if (response.status !== 200) {
              const res = await response.json();
              let errorDescription = '';
              let errorStatus = '';
              if (response.status === 403) {
                // reset password
                errorStatus = res.account;
                errorDescription = this.signInVerificationErrors[errorStatus];
              } else {
                errorStatus = response.status;
                errorDescription = res.error_description || '';
              }
              let errorMessage = '';
              if (errorDescription) {
                const splittedError = errorDescription.split(':').length > 1 ? errorDescription.split(':')[1] : null;
                errorMessage = splittedError ? splittedError.split('.')[0] : errorDescription;
              } else {
                errorMessage = res.errors && res.errors.length ? res.errors[0] : '';
              }
              return {
                errorStatus,
                errorMessage,
                token: res.access_token || null
              };
            }
            return response.json();
          }).then(json => {
            if (json.access_token) {
              this.auth.token = json.access_token;
              const decoded = JSON.parse(atob(json.access_token.split('.')[1]));
              if (decoded.extension_user_type) {
                this.signInRedirection(decoded, json);
              }
            } else {
              this.errors = [];
              if (json.errorStatus === 'password_expired') {
                this.errors.pushObject(json.errorMessage);
                const url = this.getB2CRedirectURL('B2C_1A_PASSWORD_RESET', {
                  redirectUrl: `${_environment.default.B2C_REDIRECT_URI}/signin`
                });
                setTimeout(() => {
                  return window.location.assign(url);
                }, 2000);
              } else if (json.errorStatus === 'password_expiring') {
                this.errors.pushObject(json.errorMessage);
                const url = this.getB2CRedirectURL('B2C_1A_CHANGE_PASSWORD', {
                  token: json.token
                });
                setTimeout(() => {
                  return window.location.assign(url);
                }, 2000);
              } else if (json.errorStatus === 'verification_pending') {
                this.errors.pushObject(json.errorMessage);
                this.sendVerification(this.user.email);
                setTimeout(() => {
                  this.router.transitionTo('signup.verify', {
                    queryParams: {
                      email: this.user.email
                    }
                  });
                }, 2000);
              } else {
                this.errors.pushObject(json.errorMessage);
              }
            }
          }).catch(error => {
            this.errors.pushObject(error);
            this.isSignInInProgress = false;
          }).finally(() => {
            this.isSignInInProgress = false;
          });
        } catch (error) {
          this.errors.pushObject(error);
          this.isSignInInProgress = false;
        }
      }
    }
    getB2CRedirectURL(B2C_POLICY, data) {
      const host = `https://${_environment.default.B2C_TENANT_NAME}.b2clogin.com/${_environment.default.B2C_TENANT_NAME}.onmicrosoft.com/oauth2/v2.0/authorize`;
      const redirectUri = `&redirect_uri=${data.redirectUrl ? data.redirectUrl : _environment.default.B2C_REDIRECT_URI}`;
      const clientId = `&client_id=${_environment.default.B2C_CLIENT_ID}`;
      const nonce = '&nonce=defaultNonce';
      const policy = `?p=${B2C_POLICY}`;
      const baseUrl = `${host}${policy}${clientId}${nonce}${redirectUri}`;
      const redirects = {
        B2C_1A_PASSWORD_RESET: ['&scope=openid&response_type=id_token&response_mode=form_post&prompt=login'],
        B2C_1A_CHANGE_PASSWORD: ['&scope=openid&response_type=id_token&prompt=login', '&client_assertion_type=urn%3Aietf%3Aparams%3Aoauth%3Aclient-assertion-type%3Ajwt-bearer&&client_assertion=', data.token]
      };
      return `${baseUrl}${redirects[B2C_POLICY].join('')}`;
    }
    sendVerification(email) {
      const data = JSON.stringify({
        email
      });
      try {
        fetch(`/api/send_verification_code`, {
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw new Error('Error in email verification');
          }
        }).then(json => {
          this.errorMsg = null;
        }).catch(error => {
          this.errorMsg = error.toString().split(':')[1];
        });
      } catch (error) {
        this.errorMsg = error.toString().split(':')[1];
      }
    }
    toggleInputType(property) {
      this.inputTypes = {
        ...this.inputTypes,
        [property]: this.inputTypes[property] === 'password' ? 'text' : 'password'
      };
    }
    socialSignIn(ssoType) {
      const uri = (0, _sso.generateUri)({
        isSignUp: false,
        ssoType
      });
      window.location.assign(uri);
    }
    getExpertUrl(expertData) {
      const {
        publicFrontend,
        incompleteProfile,
        type,
        id
      } = expertData;
      if (publicFrontend) {
        return this.links.withToken(`/expert/${type}/${id}/details?expertApplies=true`);
      }
      return this.links.withToken(`/expert/${incompleteProfile ? 'autofill' : 'opportunities'}`);
    }
    getClientUrl(hasOpportunities) {
      const {
        open,
        pendingApproval
      } = hasOpportunities;
      if (open) {
        return this.links.withToken('/opportunities/experfy');
      } else if (pendingApproval) {
        return this.links.withToken('/opportunities?status=pending_approval');
      }
      return this.links.withToken('/opportunities/experfy');
    }
    getAdminURL() {
      return this.links.withToken('/tenant/dashboard');
    }
    getStaffURL() {
      return this.links.withToken('/task-dashboard');
    }
    getReferrerURL(refferer) {
      const decodedUrl = atob(refferer.replaceAll('-', '/'));
      return `${decodedUrl}?id_token=${this.auth.token}`;
    }
    signInRedirection(user, json) {
      const {
        extension_user_type: userType
      } = user;
      const {
        hasOpportunities
      } = json;
      const hasRefferer = this.referrer;
      if (hasRefferer) {
        return window.location.assign(this.getReferrerURL(hasRefferer));
      } else if (userType === 'expert') {
        const expertData = {
          publicFrontend: this.opportunityId || this.talentCloudId,
          incompleteProfile: json.profile_completion_status !== 'complete',
          type: this.opportunityId ? 'opportunities' : 'talentclouds',
          id: this.opportunityId || this.talentCloudId
        };
        return window.location.assign(this.getExpertUrl(expertData));
      } else if (userType === 'site_admin') {
        return window.location.assign(this.getAdminURL());
      } else if (userType === 'experfy_staff_user' || userType === 'experfy_staff_admin_user') {
        return window.location.assign(this.getStaffURL());
      } else if (userType === 'tod_client' && hasOpportunities) {
        return window.location.assign(this.getClientUrl(hasOpportunities));
      } else {
        this.transitionToRoute(this.landingPages[userType]);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "links", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "auth", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        email: '',
        password: ''
      };
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "inputTypes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        password: 'password'
      };
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fieldError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isSignInInProgress", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateUser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "forgotPasswordHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "forgotPasswordHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSignInKeydown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSignInKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "signIn", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "signIn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleInputType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleInputType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "socialSignIn", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "socialSignIn"), _class.prototype)), _class);
  _exports.default = SigninController;
});